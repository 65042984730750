import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  Box,
  Checkbox,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import { previewPlaylist, getVenue } from "../api";
import readStreamAsBase64 from "../utils/readStreamAsBase64";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CopyPlaylistModal from "./CopyPlaylistModal";
import { DataGrid } from "@mui/x-data-grid";
import AccessTime from "@mui/icons-material/AccessTime";
import Euro from "@mui/icons-material/Euro";
import Dollar from "@mui/icons-material/AttachMoney";
import currencies from "../utils/currencies";
import { getTimeInSelectedTimezone } from "../utils/dateConvert";
import { zonedTimeToUtc } from "date-fns-tz";

import { find } from "geo-tz";

// const env = process.env.REACT_APP_ENV || "prod";
// const user = JSON.parse(localStorage.getItem("user"));
const startHour = 12;
const endHour = 12;
const timeOptions = [];
for (let i = startHour; i < 24; i++) {
  for (let j = 0; j < 4; j++) {
    const time = `${i.toString().padStart(2, "0")}:${(15 * j)
      .toString()
      .padStart(2, "0")}`;
    timeOptions.push({
      label: time,
      value: time,
    });
  }
}
for (let i = 0; i < endHour; i++) {
  for (let j = 0; j < 4; j++) {
    const time = `${i.toString().padStart(2, "0")}:${(15 * j)
      .toString()
      .padStart(2, "0")}`;
    timeOptions.push({
      label: time,
      value: time,
    });
  }
}

export default function PlaylistModal(props) {
  const currencySymbol = currencies[props.venueCurrency] || "€";
  const currency = props.venueCurrency || "EUR";

  const columns = [
    {
      field: "item",
      headerName: "Track",
      flex: 1,
      editable: true,
    },
    {
      field: "price",
      headerName: `Price (${currencySymbol})`,
      type: "number",
      editable: true,
    },
  ];

  const handleCellEditCommit = (e) => {
    setState((state) => {
      let rows = [...state.rows];
      const oldItem = rows.find((x) => x.id === e.id);
      const newItem = { ...oldItem, [e.field]: e.value };
      const index = rows.indexOf(oldItem);
      rows.splice(index, 1, newItem);
      return { ...state, rows };
    });
  };

  const handleSortModelChange = (e) => {
    if (!e[0]?.sort) return;
    setState((state) => {
      let rows = [...state.rows];
      rows.sort((a, b) => {
        if (e[0].field === "item") {
          if (e[0].sort === "asc") {
            return a[e[0].field].localeCompare(b[e[0].field]);
          } else if (e[0].sort === "desc") {
            return b[e[0].field].localeCompare(a[e[0].field]);
          }
        } else if (e[0].field === "price") {
          if (e[0].sort === "asc") {
            return (a[e[0].field] || 0) - (b[e[0].field] || 0);
          } else if (e[0].sort === "desc") {
            return (b[e[0].field] || 0) - (a[e[0].field] || 0);
          }
        }
        return 0;
      });
      return { ...state, rows };
    });
  };

  const setTimezones = (lat, lon) => {
    const venueTimezone = find(lat, lon);

    setVenueTimezone(venueTimezone[0]);
  };

  const [state, setState] = useState({});
  const [venueTimezone, setVenueTimezone] = useState("");

  useEffect(() => {
    (async () => {
      const venueItem = await getVenue(props.venueId);

      setTimezones(venueItem.coords.latitude, venueItem.coords.longitude);
    })();
  }, [props.venueId]);

  useEffect(() => {
    (async () => {
      let startTime =
        props.playlist?.startTime &&
        new Date(
          props.playlist.startTime -
            new Date(props.playlist.startTime).getTimezoneOffset() * 60000
        )
          .toISOString()
          .slice(11, 16);

      let endTime =
        props.playlist?.endTime &&
        new Date(
          props.playlist.endTime -
            new Date(props.playlist.endTime).getTimezoneOffset() * 60000
        )
          .toISOString()
          .slice(11, 16);

      const startDate = new Date(`${props.eventDate}T${startTime}`);
      const endDate = new Date(`${props.eventDate}T${endTime}`);

      if (startTime < "12:00") {
        startDate.setDate(startDate.getDate() + 1);
      }

      if (startTime) {
        startTime = getTimeInSelectedTimezone(startDate, venueTimezone);
      }

      if (endTime) {
        endTime = getTimeInSelectedTimezone(endDate, venueTimezone);
      }

      setState((s) => ({
        ...s,
        ...props.playlist,
        venueId: props.venueId,
        eventId: props.eventId,
        eventDate: props.eventDate,
        defaultPrice: props.playlist?.defaultPrice?.replace(currencySymbol, ""),
        suggestionsPrice: props.playlist?.suggestionsPrice?.replace(
          currencySymbol,
          ""
        ),
        startTime,
        endTime,
      }));
    })();
  }, [
    props.playlist,
    props.venueId,
    props.eventId,
    props.eventDate,
    props.startTime,
    props.endTime,
    currencySymbol,
    venueTimezone,
  ]);

  const handleImport = async (e) => {
    const playlistFile = e.target.files[0];
    const base64Playlist = await readStreamAsBase64(playlistFile);

    const playlist = await previewPlaylist({ base64: base64Playlist });

    setState((s) => ({
      ...s,
      playlist: [
        ...new Set([
          ...(playlist || []).map((x) => ({ item: x })),
          ...(s.playlist || []),
        ]),
      ],
    }));

    e.target.value = null;
  };

  useEffect(() => {
    setState((s) => ({
      ...s,
      rows: s.playlist
        ? s.playlist.map((x) => ({
            id: x.item,
            item: x.item,
            price: x.price?.replace(currencySymbol, ""),
          }))
        : [],
    }));
  }, [state.playlist, currencySymbol]);

  const handleCopy = () => {
    setState((s) => ({
      ...s,
      open: true,
      handleConfirm: (playlist) => {
        setState((s) => ({
          ...s,
          playlist: [...new Set([...(playlist || []), ...(s.playlist || [])])],
        }));
      },
    }));
  };

  const handleClose = () => {
    setState((s) => ({
      ...s,
      open: false,
      handleConfirm: undefined,
    }));
  };

  const handleClear = () => {
    setState((s) => ({
      ...s,
      playlist: undefined,
    }));
  };

  const handleConfirm = async () => {
    if (!state.startTime) {
      setState((s) => ({
        ...s,
        errors: {
          endTime: "Start Time is required.",
        },
      }));
      return;
    }

    if (!state.endTime) {
      setState((s) => ({
        ...s,
        errors: {
          endTime: "End Time is required.",
        },
      }));
      return;
    }

    const startDate = new Date(`${state.eventDate}T${state.startTime}`);
    if (state.startTime < "12:00") {
      startDate.setDate(startDate.getDate() + 1);
    }
    const startTimeInVenueTimezone = zonedTimeToUtc(
      startDate,
      venueTimezone
    ).getTime();

    const endDate = new Date(`${state.eventDate}T${state.endTime}`);
    if (state.endTime <= "12:00") {
      endDate.setDate(endDate.getDate() + 1);
    }
    const endTimeInVenueTimezone = zonedTimeToUtc(
      endDate,
      venueTimezone
    ).getTime();

    setState((s) => ({
      ...s,
      isLoading: true,
      errors: undefined,
    }));
    const body = {
      id: state.id,
      startTime: startTimeInVenueTimezone,
      endTime: endTimeInVenueTimezone,
      venueId: state.venueId,
      eventId: state.eventId,
      playlist: state.rows?.map((x) => ({
        item: x.item,
        price: x.price ? `${currencySymbol}${x.price}` : undefined,
      })),
      price: parseFloat(state.defaultPrice?.replace(currencySymbol, ""))
        ? `${currencySymbol}${state.defaultPrice}`
        : undefined,
      defaultPrice: parseFloat(state.defaultPrice?.replace(currencySymbol, ""))
        ? `${currencySymbol}${state.defaultPrice}`
        : undefined,
      maxOrdersPerPlaylist: parseInt(state.maxOrdersPerPlaylist) || 0,
      maxPaidOrdersPerPlaylist: parseInt(state.maxPaidOrdersPerPlaylist),
      hasSuggestions: state.hasSuggestions,
      suggestionsPrice: parseFloat(
        state.suggestionsPrice?.replace(currencySymbol, "")
      )
        ? `${currencySymbol}${state.suggestionsPrice}`
        : undefined,
    };
    await props.handleConfirm(body);
    props.handleClose();
  };

  const handleChange = (propName) => (event) => {
    setState((s) => ({
      ...s,
      [propName]: event.target.value,
    }));
  };

  const handleHasSuggestionsChange = (e) => {
    setState((s) => ({
      ...s,
      hasSuggestions: e.target.checked,
    }));
  };

  const handleStartTimeChange = (e, value) => {
    setState((s) => {
      const startTime = value.value;
      return {
        ...s,
        startTime,
      };
    });
  };

  const handleEndTimeChange = (e, value) => {
    setState((s) => {
      const endTime = value.value;
      return {
        ...s,
        endTime,
      };
    });
  };

  return (
    <>
      {venueTimezone.length && (
        <Dialog
          open={true}
          onClose={props.handleClose}
          PaperProps={{
            style: {
              backgroundColor: "#d8c8f4",
              color: "#4b445b",
            },
          }}
        >
          <DialogTitle>
            {state.id ? "Edit Playlist" : "Create Playlist"}
          </DialogTitle>
          <DialogContent>
            <TextField
              label="Timezone"
              variant="outlined"
              value={venueTimezone || ""} // Set the value of the field
              disabled // Disable the field
              fullWidth
              sx={{
                marginTop: "10px",
                marginBottom: "5px",
              }} // Optional: Makes the field stretch to the container width
            />
            <Autocomplete
              disablePortal
              id="startTime-dropdown"
              options={timeOptions}
              value={state.startTime}
              disableClearable
              onChange={handleStartTimeChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  label="Start Time"
                  id="startTime"
                  type="text"
                  required
                  error={!!state.errors?.startTime}
                  helperText={state.errors?.startTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        {params.InputProps.startAdornment}
                        <InputAdornment position="center">
                          <AccessTime
                            sx={{
                              marginLeft: "4px",
                              marginRight: "4px",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </InputAdornment>
                      </>
                    ),
                  }}
                />
              )}
            />
            <Autocomplete
              disablePortal
              id="endTime-dropdown"
              options={timeOptions}
              value={state.endTime}
              disableClearable
              onChange={handleEndTimeChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  label="End Time"
                  id="endTime"
                  type="text"
                  required
                  error={!!state.errors?.endTime}
                  helperText={state.errors?.endTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        {params.InputProps.startAdornment}
                        <InputAdornment position="center">
                          <AccessTime
                            sx={{
                              marginLeft: "4px",
                              marginRight: "4px",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </InputAdornment>
                      </>
                    ),
                  }}
                />
              )}
            />
            <TextField
              autoComplete="off"
              margin="dense"
              id="maxOrdersPerPlaylist"
              label="Playlist Max Free Requests"
              type="number"
              fullWidth
              variant="outlined"
              value={state.maxOrdersPerPlaylist}
              onChange={handleChange("maxOrdersPerPlaylist")}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={state.maxOrdersPerPlaylist}
            />
            <TextField
              autoComplete="off"
              margin="dense"
              id="defaultPrice"
              label="Playlist Price Per Song"
              type="number"
              fullWidth
              variant="outlined"
              value={state.defaultPrice}
              onChange={handleChange("defaultPrice")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currency === "EUR" && (
                      <Euro sx={{ width: "20px", height: "20px" }} />
                    )}
                    {currency === "USD" && (
                      <Dollar sx={{ width: "20px", height: "20px" }} />
                    )}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={state.defaultPrice}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.hasSuggestions}
                  onChange={handleHasSuggestionsChange}
                />
              }
              label="Custom Requests"
              key="hasSuggestions"
            />
            <TextField
              autoComplete="off"
              margin="dense"
              id="suggestionsPrice"
              label="Custom Request Price Per Song"
              type="number"
              fullWidth
              variant="outlined"
              value={state.suggestionsPrice}
              onChange={handleChange("suggestionsPrice")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currency === "EUR" && (
                      <Euro sx={{ width: "20px", height: "20px" }} />
                    )}
                    {currency === "USD" && (
                      <Dollar sx={{ width: "20px", height: "20px" }} />
                    )}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={state.suggestionsPrice}
            />
            <TextField
              autoComplete="off"
              margin="dense"
              variant="outlined"
              fullWidth
              label="Playlist"
              multiline
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: "div",
              }}
              inputProps={{
                children: (
                  <>
                    <div>
                      <Button
                        variant="outlined"
                        component="label"
                        sx={{ marginBottom: "16px" }}
                      >
                        <UploadFileIcon
                          sx={{ fontSize: "20px", marginRight: "4px" }}
                        />
                        Import playlist from file
                        <input
                          type="file"
                          hidden
                          onChange={handleImport}
                          accept=".crate,.m3u,.m3u8,.txt"
                        />
                      </Button>
                      <Button
                        variant="outlined"
                        component="label"
                        sx={{ marginBottom: "16px", marginLeft: "8px" }}
                        onClick={handleCopy}
                      >
                        <FileCopyIcon
                          sx={{ fontSize: "20px", marginRight: "4px" }}
                        />
                        Copy playlist from past event
                      </Button>
                      <Button
                        variant="outlined"
                        component="label"
                        sx={{ marginBottom: "16px", marginLeft: "8px" }}
                        onClick={handleClear}
                      >
                        <HighlightOffIcon
                          sx={{ fontSize: "20px", marginRight: "4px" }}
                        />
                        Delete current
                      </Button>
                    </div>
                    <Box
                      sx={{
                        height:
                          Math.min(100, Math.max(1, state.rows?.length || 0)) *
                            52 +
                          110,
                        width: "100%",
                      }}
                    >
                      <DataGrid
                        rows={state.rows}
                        columns={columns}
                        onCellEditCommit={handleCellEditCommit}
                        onSortModelChange={handleSortModelChange}
                        // pageSize={100}
                        // checkboxSelection
                        // disableSelectionOnClick
                        // onSelectionModelChange={(e) => console.log(e)}
                        // rowReordering
                        // onRowOrderChange={(e) => console.log(e)}
                        // experimentalFeatures={{ newEditingApi: true }}
                        sx={{
                          borderColor: "primary.main",
                          "& .MuiDataGrid-columnHeaders": {
                            borderColor: "primary.main",
                          },
                          "& .MuiDataGrid-columnHeaders:hover": {
                            color: "primary.main",
                          },
                          "& .MuiDataGrid-columnSeparator": {
                            color: "primary.main",
                          },
                          "& .MuiDataGrid-cell": {
                            borderColor: "primary.main",
                          },
                          "& .MuiDataGrid-cell:hover": {
                            color: "primary.main",
                          },
                        }}
                      />
                    </Box>
                  </>
                ),
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} variant="contained">
              Cancel
            </Button>
            <Button
              onClick={handleConfirm}
              variant="contained"
              disabled={state.isLoading}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {state.open && (
        <CopyPlaylistModal
          playlistId={state.id}
          eventId={props.eventId}
          venueId={props.venueId}
          handleConfirm={state.handleConfirm}
          handleClose={handleClose}
        />
      )}
    </>
  );
}
