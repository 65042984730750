import { zonedTimeToUtc, utcToZonedTime, format } from "date-fns-tz";

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getTimeInSelectedTimezone = (date, timezone) => {
  const utcTime = zonedTimeToUtc(date, userTimezone);

  const dateInSelectedTimezone = utcToZonedTime(utcTime, timezone);

  return format(dateInSelectedTimezone, "HH:mm", {
    timeZone: timezone,
  });
};
