import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Alert,
  Typography,
  IconButton,
} from "@mui/material";
import { useRef, useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth-slice";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  login,
  signup,
  forgottenPassword,
  resetPassword,
  loginWithFb,
  loginWithApple,
} from "../../api";
import UserContext from "../../contexts/UserContext";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FacebookIcon from "@mui/icons-material/Facebook";
import AppleIcon from "@mui/icons-material/Apple";

const AuthForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usernameOrEmailInputRef = useRef();
  const usernameInputRef = useRef();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const confirmPasswordInputRef = useRef();
  const authCtx = useSelector((store) => store.auth);
  const [searchParams] = useSearchParams();

  const handleCancel = () => {
    dispatch(authActions.closeForm());
    navigate("/events");
  };

  const userContext = useContext(UserContext);

  const [state, setState] = useState({});

  const handleConfirm = async () => {
    setState((s) => ({
      ...s,
      errorMessage: undefined,
      isLoading: true,
    }));
    if (props.title === "Log in") {
      const user = {
        usernameOrEmail: usernameOrEmailInputRef.current.value,
        password: passwordInputRef.current.value,
      };
      try {
        const result = await login(user);
        userContext.setUser(result);
        handleCancel();
      } catch (e) {
        setState((s) => ({
          ...s,
          errorMessage: e,
          isLoading: false,
        }));
      }
    }
    if (props.title === "Sign up") {
      const user = {
        username: usernameInputRef.current.value,
        email: emailInputRef.current.value,
        password: passwordInputRef.current.value,
        confirmPassword: confirmPasswordInputRef.current.value,
      };
      try {
        const result = await signup(user);
        userContext.setUser(result);
        handleCancel();
      } catch (e) {
        setState((s) => ({
          ...s,
          errorMessage: e,
          isLoading: false,
        }));
      }
    }
    if (props.title === "Forgotten Password") {
      const user = {
        email: emailInputRef.current.value,
      };
      try {
        await forgottenPassword(user);
        setState((s) => ({
          ...s,
          successMessage: `A password reset link has been sent to ${emailInputRef.current.value}.`,
          isLoading: false,
        }));
      } catch (e) {
        setState((s) => ({
          ...s,
          errorMessage: e,
          isLoading: false,
        }));
      }
    }
    if (props.title === "Reset Password") {
      const user = {
        email: searchParams.get("email"),
        token: searchParams.get("token"),
        password: passwordInputRef.current.value,
        confirmPassword: confirmPasswordInputRef.current.value,
      };
      try {
        const result = await resetPassword(user);
        userContext.setUser(result);
        handleCancel();
      } catch (e) {
        setState((s) => ({
          ...s,
          errorMessage: e,
          isLoading: false,
        }));
      }
    }
  };

  const handleLoginWithFb = async (e) => {
    setState((s) => ({
      ...s,
      errorMessage: undefined,
      isLoading: true,
    }));
    try {
      const result = await loginWithFb({
        token: e.accessToken,
        userId: e.userID,
        email: e.email,
        name: e.name,
      });
      userContext.setUser(result);
      handleCancel();
    } catch (e) {
      setState((s) => ({
        ...s,
        errorMessage: "An error occurred.",
        isLoading: false,
      }));
    }
  };

  const handleLoginWithApple = async () => {
    setState((s) => ({
      ...s,
      errorMessage: undefined,
      isLoading: true,
    }));
    try {
      const response = await window.AppleID.auth.signIn();
      const result = await loginWithApple({
        identityToken: response?.authorization?.id_token,
        // email: response?.user?.email,
        firstName: response?.user?.name?.firstName,
        lastName: response?.user?.name?.lastName,
        clientId: "com.test.vibysignin",
      });
      userContext.setUser(result);
      handleCancel();
    } catch (e) {
      if (e.error !== "popup_closed_by_user") {
        setState((s) => ({
          ...s,
          errorMessage: "An error occurred.",
          isLoading: false,
        }));
      } else {
        setState((s) => ({
          ...s,
          isLoading: false,
        }));
      }
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
  
    if (props.title === "Log in") {
      script.textContent = `
      AppleID.auth.init({
        clientId: "com.test.vibysignin",
        scope: "name email",
        redirectURI: "https://app.viby-music.com/login",
        usePopup: true,
      });
      `;
    } else if (props.title === "Sign up") {
      script.textContent = `
      AppleID.auth.init({
        clientId: "com.test.vibysignin",
        scope: "name email",
        redirectURI: "https://app.viby-music.com/register",
        usePopup: true,
      });
      `;
    }
    
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, [props.title]);

  return (
    <>
      <Dialog
        open={authCtx.shouldOpenAuthForm}
        onBackdropClick={handleCancel}
        PaperProps={{
          style: {
            backgroundColor: "#d8c8f4",
            color: "#4b445b",
          },
        }}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          {(props.title === "Log in" || props.title === "Sign up") && (
            <div>
              <FacebookLogin
                appId="1871655919785755"
                fields="name,email"
                callback={handleLoginWithFb}
                isMobile={false}
                render={(renderProps) => (
                  <IconButton
                    color="secondary"
                    onClick={renderProps.onClick}
                    title="Log in with Facebook"
                    style={{
                      height: "48px",
                      padding: 0,
                      color: "#fff",
                      backgroundColor: "#1977F2",
                      borderRadius: "8px",
                      marginBottom: "12px",
                    }}
                  >
                    <FacebookIcon
                      sx={{
                        height: "32px",
                        width: "32px",
                        margin: "8px",
                      }}
                    />
                    <Typography variant="h6" sx={{ marginRight: "8px" }}>
                      Log in with Facebook
                    </Typography>
                  </IconButton>
                )}
              />
            </div>
          )}
          {(props.title === "Log in" || props.title === "Sign up") && (
            <div>
              <IconButton
                color="secondary"
                onClick={handleLoginWithApple}
                title="Log in with Apple"
                style={{
                  height: "48px",
                  padding: 0,
                  color: "#fff",
                  backgroundColor: "#000000",
                  borderRadius: "8px",
                  marginBottom: "12px",
                }}
              >
                <AppleIcon
                  sx={{
                    height: "32px",
                    width: "32px",
                    margin: "8px",
                  }}
                />
                <Typography variant="h6" sx={{ marginRight: "8px" }}>
                  Log in with Apple
                </Typography>
              </IconButton>
            </div>
          )}
          {(props.title === "Log in" || props.title === "Sign up") && (
            <Typography variant="h6" sx={{ marginBottom: "12px" }}>
              Or
            </Typography>
          )}
          {props.title === "Log in" && (
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="usernameOrEmail"
              label="Username/Email"
              type="text"
              fullWidth
              variant="outlined"
              inputRef={usernameOrEmailInputRef}
            />
          )}
          {props.title === "Sign up" && (
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="username"
              label="Username"
              type="text"
              fullWidth
              variant="outlined"
              inputRef={usernameInputRef}
            />
          )}
          {(props.title === "Sign up" ||
            props.title === "Forgotten Password") && (
            <TextField
              autoComplete="off"
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="outlined"
              inputRef={emailInputRef}
            />
          )}
          {(props.title === "Sign up" ||
            props.title === "Log in" ||
            props.title === "Reset Password") && (
            <TextField
              autoComplete="off"
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              variant="outlined"
              inputRef={passwordInputRef}
            />
          )}
          {(props.title === "Sign up" || props.title === "Reset Password") && (
            <TextField
              autoComplete="off"
              margin="dense"
              id="confirmPassword"
              label="Confirm Password"
              type="password"
              fullWidth
              variant="outlined"
              inputRef={confirmPasswordInputRef}
            />
          )}
          {state.errorMessage && (
            <Alert severity="error">
              {state.errorMessage}
              {props.title === "Sign up" || props.title === "Log in" ? (
                <>
                  {(props.title === "Sign up" || props.title === "Log in") && (
                    <>
                      {" "}
                      <a href="/forgottenpassword">Forgot your password?</a>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </Alert>
          )}
          {(props.title === "Sign up" || props.title === "Log in") &&
            !state.errorMessage && (
              <a href="/forgottenpassword">Forgotten Password</a>
            )}
          {props.title === "Forgotten Password" && state.successMessage && (
            <Alert severity="success">{state.successMessage}</Alert>
          )}
          <div style={{ marginTop: 16 }}>
            {(props.title === "Sign up" || props.title === "Log in") &&
              !state.errorMessage && (
                <>
                  By clicking {props.title}, you acknowledge that you have
                  agreed to the <a href="/termsofuse">Terms of Use</a>, and read
                  the <a href="/privacypolicy">Privacy Policy</a>
                </>
              )}
          </div>
        </DialogContent>
        <DialogActions>
          {!state.successMessage ? (
            <>
              <Button onClick={handleCancel} variant="contained">
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={handleConfirm}
                variant="contained"
                disabled={state.isLoading}
              >
                {props.title === "Sign up" || props.title === "Log in"
                  ? props.title
                  : "Confirm"}
              </Button>
            </>
          ) : (
            <Button onClick={handleCancel} variant="contained">
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AuthForm;
